<template>
    <div class="bg-white rounded-lg shadow-lg p-4 overflow-hidden">
        <div class="-mx-4 -mt-4 ">
            <slot name="screenshot"/>
        </div>
        <div class="flex items-center gap-4 mt-6">
            <div class="">
                <slot name="icon"/>
            </div>

            <slot name="title"/>
        </div>

        <div class="mt-6">
            <slot name="content"/>
        </div>
    </div>

</template>
<script setup lang="ts">
</script>
